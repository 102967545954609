<template>
  <div :class="$style.wrap">
    <img
      @click="handle2Back"
      :class="$style.back"
      src="@/assets/img/back.png"
    />
    <div :class="$style.title">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["title", "redirect"],
  methods: {
    // 返回上一步
    handle2Back() {
      if (this.redirect) {
        this.$router.replace(this.redirect);
        return;
      }
      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  position: relative;
  width: 100%;
  background-color: #121212;
  .back {
    width: 40px;
    height: 40px;
    margin-left: 16px;
  }
  .title {
    position: absolute;
    top: 20px;
    left: 50%;
    font-size: 18px;
    line-height: 40px;
    transform: translateX(-50%);
    letter-spacing: 1px;
  }
}
</style>
