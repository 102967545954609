<template>
  <div>
    <Back />
    <div :class="$style.content">
      <img :class="$style.banner" :src="info.image_url" />
      <p :class="$style.name">{{ info.name }}</p>
      <div :class="$style.ddcWrap">{{ info.ddc_no }}</div>
      <p :class="$style.line">
        <span :class="$style.label">收藏者：</span>
        <span>{{ owner }}</span>
      </p>
      <p :class="$style.line">
        <span :class="$style.label">生成时间：</span>
        <span>{{ info.created_at }}</span>
      </p>
      <p :class="$style.line">
        <span :class="$style.label">链类型：</span>
        <span>{{ info.chain_type }}</span>
      </p>
      <p :class="$style.line">
        <span :class="$style.label">链上地址：</span>
        <span :class="$style.copyWrap">
          <span :class="$style.address" id="copycode">{{
            info.ddc_address
          }}</span>
          <i @click="handleCopy" :class="$style.copy"></i>
        </span>
      </p>
      <div :class="$style.origin">
        <span :class="$style.label">作品源自</span>：{{ info.artist_name }}
      </div>
    </div>
    <ActionSheet :info="info" :path="path" />
  </div>
</template>

<script>
import Back from "@/components/Back";
import ActionSheet from "@/components/ActionSheet";
import { getStorage } from "@/utils/util";
import { fetchArtistDdcDetail, fetchDdcDetail } from "@/api";
export default {
  components: {
    Back,
    ActionSheet,
  },
  data() {
    return {
      info: {},
      owner: getStorage("user_info").nickname,
      ddc_id: this.$route.query.ddc_id, // 作品详情id
      department_id: this.$route.query.department_id, // 1: 艺术家作品详情，2国家纺织中心作品详情
    };
  },
  computed: {
    path() {
      const pathMap = {
        1: "cloth",
        2: "spin",
      };
      return pathMap[this.department_id];
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (Number(this.department_id) === 1) {
        // 获取艺术家中心的详情
        const data = { ddc_id: this.ddc_id };
        fetchArtistDdcDetail(data).then((res) => {
          this.info = res;
        });
      }
      if (Number(this.department_id) === 2) {
        const data = { ddc_id: this.ddc_id };
        fetchDdcDetail(data).then((res) => {
          this.info = res;
        });
      }
    },
    handleCopy() {
      const range = document.createRange();
      range.selectNode(document.getElementById("copycode")); //获取复制内容的 id 选择器
      const selection = window.getSelection(); //创建 selection对象
      if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
      selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
      document.execCommand("copy"); //复制选中的文字到剪贴板
      this.$toast("复制成功");
      selection.removeRange(range); // 移除选
    },
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 24px 32px 80px;
  text-align: center;
  .banner {
    width: 311px;
    height: 311px;
    border-radius: 24px;
  }
  .name {
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    margin: 18px 0 9px;
  }
  .ddcWrap {
    margin: 0 auto 14px;
    background-image: url("~@/assets/img/ddc_no.png");
    background-size: 100% 100%;
    width: 99px;
    height: 32px;
    box-sizing: border-box;
    padding: 4px 0 0 10px;
    font-size: 14px;
    color: #000000;
    line-height: 28px;
    text-align: center;
  }
  .line {
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    font-weight: 500;
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 8px;
    .label {
      color: #d0d0d0;
      flex-shrink: 0;
    }
    .copyWrap {
      display: flex;
      text-align: right;
      align-items: center;
      .address {
        width: 200px;
        margin-right: 10px;
        @include ellipsis(1);
      }
      .copy {
        width: 11px;
        height: 11px;
        background: url("~@/assets/img/copy.png");
        background-size: 100% 100%;
      }
    }
  }
  .origin {
    text-align: right;
    font-size: 14px;
    color: #e7e7e7;
    line-height: 20px;
    margin: 50px 0 25px;
    .label {
      color: #d0d0d0;
    }
  }
}
</style>
