// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/ddc_no.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/copy.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".collect_content_1i79o{padding:.64rem .85333rem 2.13333rem;text-align:center}.collect_content_1i79o .collect_banner_AVsey{width:8.29333rem;height:8.29333rem;border-radius:.64rem}.collect_content_1i79o .collect_name_3HY3H{font-size:.53333rem;font-weight:500;line-height:.96rem;margin:.48rem 0 .24rem}.collect_content_1i79o .collect_ddcWrap_23ams{margin:0 auto .37333rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;width:2.64rem;height:.85333rem;box-sizing:border-box;padding:.10667rem 0 0 .26667rem;font-size:.37333rem;color:#000;line-height:.74667rem;text-align:center}.collect_content_1i79o .collect_line_xszQE{display:flex;justify-content:space-between;line-height:.53333rem;font-weight:500;color:#fff;font-size:.37333rem;margin-bottom:.21333rem}.collect_content_1i79o .collect_line_xszQE .collect_label_TzODv{color:#d0d0d0;flex-shrink:0}.collect_content_1i79o .collect_line_xszQE .collect_copyWrap_1RvUc{display:flex;text-align:right;align-items:center}.collect_content_1i79o .collect_line_xszQE .collect_copyWrap_1RvUc .collect_address_7kmev{width:5.33333rem;margin-right:.26667rem;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;word-break:break-all}.collect_content_1i79o .collect_line_xszQE .collect_copyWrap_1RvUc .collect_copy_3gAbm{width:.29333rem;height:.29333rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%}.collect_content_1i79o .collect_origin_ySRcS{text-align:right;font-size:.37333rem;color:#e7e7e7;line-height:.53333rem;margin:1.33333rem 0 .66667rem}.collect_content_1i79o .collect_origin_ySRcS .collect_label_TzODv{color:#d0d0d0}", ""]);
// Exports
exports.locals = {
	"content": "collect_content_1i79o",
	"banner": "collect_banner_AVsey",
	"name": "collect_name_3HY3H",
	"ddcWrap": "collect_ddcWrap_23ams",
	"line": "collect_line_xszQE",
	"label": "collect_label_TzODv",
	"copyWrap": "collect_copyWrap_1RvUc",
	"address": "collect_address_7kmev",
	"copy": "collect_copy_3gAbm",
	"origin": "collect_origin_ySRcS"
};
module.exports = exports;
