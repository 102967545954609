<template>
  <div :class="$style.wrap">
    <span :class="$style.action" @click="handleShare">
      <img :class="$style.icon" src="@/assets/img/act-share.png" />
    </span>
    <!-- <span :class="[$style.action, $style.border]" @click="handleGive">
      <img :class="$style.icon" src="@/assets/img/act-give.png" />
    </span> -->
    <span :class="$style.action" @click="handleDown">
      <img :class="$style.icon" src="@/assets/img/act-download.png" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String, // spin国家纺织中心作品详情，cloth艺术家作品详情,用于生成对应的详情二维码作为区分
    },
  },
  methods: {
    handleShare() {
      this.$router.push({
        path: "/share",
        query: {
          info: JSON.stringify(this.info),
          path: this.path,
        },
      });
    },
    handleGive() {
      this.$router.push({
        path: "/give/detail",
        query: {
          info: JSON.stringify(this.info),
          path: this.path,
        },
      });
    },
    handleDown() {
      const name = new Date().getTime();
      const url = this.info.ddc_image_url || this.info.image_url;
      this.downloadImg(url, name);
    },
    downloadImg(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        const url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        const a = document.createElement("a"); // 生成一个a元素
        const event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  width: 311px;
  height: 44px;
  line-height: 34px;
  padding: 5px 0;
  box-sizing: border-box;
  background: #303030;
  border-radius: 28px;
  position: fixed;
  bottom: 34px;
  left: 32px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  .action {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      border-right: 1px solid #414141;
    }
    &.border {
      border-left: 1px solid #414141;
      border-right: 1px solid #414141;
    }
    .icon {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }
}
</style>
