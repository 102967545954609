// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Back_wrap_3Q9Yv{display:flex;justify-content:space-between;align-items:center;padding-top:.53333rem;position:relative;width:100%;background-color:#121212}.Back_wrap_3Q9Yv .Back_back_14-dL{width:1.06667rem;height:1.06667rem;margin-left:.42667rem}.Back_wrap_3Q9Yv .Back_title_1IIXb{position:absolute;top:.53333rem;left:50%;font-size:.48rem;line-height:1.06667rem;transform:translateX(-50%);letter-spacing:.02667rem}", ""]);
// Exports
exports.locals = {
	"wrap": "Back_wrap_3Q9Yv",
	"back": "Back_back_14-dL",
	"title": "Back_title_1IIXb"
};
module.exports = exports;
