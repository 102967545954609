// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ActionSheet_wrap_1eo8Q{width:8.29333rem;height:1.17333rem;line-height:.90667rem;padding:.13333rem 0;box-sizing:border-box;background:#303030;border-radius:.74667rem;position:fixed;bottom:.90667rem;left:.85333rem;font-size:.42667rem;display:flex;justify-content:space-between}.ActionSheet_wrap_1eo8Q .ActionSheet_action_F8gOr{flex-grow:1;display:flex;justify-content:center;align-items:center}.ActionSheet_wrap_1eo8Q .ActionSheet_action_F8gOr.ActionSheet_border_1yLuY,.ActionSheet_wrap_1eo8Q .ActionSheet_action_F8gOr:first-child{border-right:.02667rem solid #414141}.ActionSheet_wrap_1eo8Q .ActionSheet_action_F8gOr.ActionSheet_border_1yLuY{border-left:.02667rem solid #414141}.ActionSheet_wrap_1eo8Q .ActionSheet_action_F8gOr .ActionSheet_icon_8YESc{width:.58667rem;height:.58667rem;vertical-align:middle}", ""]);
// Exports
exports.locals = {
	"wrap": "ActionSheet_wrap_1eo8Q",
	"action": "ActionSheet_action_F8gOr",
	"border": "ActionSheet_border_1yLuY",
	"icon": "ActionSheet_icon_8YESc"
};
module.exports = exports;
